@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
.card {
  /* color used to softly clip top and bottom of the .words container */
  --bg-color: #faf5de;
  background-color: var(--bg-color);
  padding: 1rem 2rem;
  border-radius: 1.25rem;
}
.loader {
  color: rgb(124, 124, 124);
  font-family: "Lora", sans-serif;
  font-weight: 500;
  font-size: 25px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 40px;
  padding: 10px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 8px;
}

.words {
  overflow: hidden;
  position: relative;
}
.words::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    var(--bg-color) 10%,
    transparent 30%,
    transparent 70%,
    var(--bg-color) 90%
  );
  z-index: 20;
}

.word {
  display: block;
  height: 100%;
  padding-left: 6px;
  color: #A48111;
  animation: spin_4991 15s infinite;
}

@keyframes spin_4991 {
    0% { transform: translateY(0); }
    10% { transform: translateY(-100%); }
    20% { transform: translateY(-200%); }
    30% { transform: translateY(-300%); }
    40% { transform: translateY(-400%); }
    50% { transform: translateY(-500%); }
    60% { transform: translateY(-600%); }
    70% { transform: translateY(-700%); }
    80% { transform: translateY(-800%); }
    90% { transform: translateY(-900%); }
    100% { transform: translateY(-1000%); }
  }
