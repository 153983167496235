.toggle {
  position: relative;
  width: 20px;
  height: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  transition-duration: .5s;
}

#bar2 {
    transition-duration: .8s;
  }
  
#bar1,#bar3 {
    width: 70%;
}

.bars {
  background-color: black;
  width: 100%;
  height: 2px;
  border-radius: 2px;
}

#checkbox-Style:checked + .toggle .bars,
#checkbox-Metal:checked + .toggle .bars,
#checkbox-Shape:checked + .toggle .bars {
  position: absolute;
  transition-duration: .5s;
}

#checkbox-Style:checked + .toggle .bars:nth-child(2),
#checkbox-Metal:checked + .toggle .bars:nth-child(2),
#checkbox-Shape:checked + .toggle .bars:nth-child(2) {
  transform: scaleX(0);
  transition-duration: .5s;
}

#checkbox-Style:checked + .toggle .bars:nth-child(1),
#checkbox-Metal:checked + .toggle .bars:nth-child(1),
#checkbox-Shape:checked + .toggle .bars:nth-child(1) {
  width: 100%;
  transform: rotate(45deg);
  transition-duration: .5s;
}

#checkbox-Style:checked + .toggle .bars:nth-child(3),
#checkbox-Metal:checked + .toggle .bars:nth-child(3),
#checkbox-Shape:checked + .toggle .bars:nth-child(3) {
  width: 100%;
  transform: rotate(-45deg);
  transition-duration: .5s;
}

#checkbox-Style:checked + .toggle,
#checkbox-Metal:checked + .toggle,
#checkbox-Shape:checked + .toggle {
  transition-duration: .5s;
  transform: rotate(180deg);
}


